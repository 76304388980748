import React, { useState } from 'react';
import styles from './faqs.module.css';

export default function Faqs(props) {
    const [ activeFaq, setActiveFaq ] = useState(1);

    return(<>
        <section>
            <div className='container'>
                <div className={styles['faqs']}>
                    <span>FAQs</span>
                    <h3>{props.heading}</h3>
                </div>
                <div className={styles["faq-accordion"]}>
                    {props.faqs.map((item, i) => (
                        <div className={styles['faq-blocks']} key={i}>
                            <div onClick={() => setActiveFaq(item.id)}>
                                <i className={`fa fa-${activeFaq === item.id?"minus":"plus"}-circle float-right`}></i>
                                <p className={styles['faq-question']}>0{item.id} <span> {item.question}</span></p>
                            </div>
                            <div className={`collapse ${activeFaq === item.id?"show":"hide"}`}>
                                <div className={styles['faq-answers']}>
                                    {item.answer}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>);
}
