import { Link } from "react-router-dom";
import styles from "./not-found.module.css";

export default function dashboard() {
    return (<>
        <div className={styles['oops-page']}>
            <img src="../images/oops.svg"  alt="invincibleocean"/>
            <h4>PAGE NOT FOUND !</h4>
            <p>we can’t seem to find the page you’re looking for !</p>
            <Link to="/"><button className="btn secondary-button px-5">Go to homepage</button></Link>
        </div>
    </>);
};