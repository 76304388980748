import React from 'react';
import styles from './footer.module.css';

export default function Footer() {
    return(<>
        <section id={styles['footer']}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <h4 className={styles['footer-heading']}>Explore</h4>
                        <ul className={styles['footer-list']}>
                            <li><a href='https://invincibleocean.com/vehicle-registration-check-rc-verification-api'><span>✓</span> Vehicle Registration Check</a></li>
                            <li><a href="https://invincibleocean.com/gst-verification-api/"><span>✓</span> GST API</a></li>
                            <li><a href="https://invincibleocean.com/pan-aadhaar-verification-api/"><span>✓</span> PAN and Adhaar Verification API</a></li>
                            <li><a href="https://invincibleocean.com/bank-statement-analyser-api-solution/"><span>✓</span> Bank Statement Analyser</a></li>
                            <li><a href="https://invincibleocean.com/explore-invincible-apis/"><span>✓</span> Explore Invincible APIs</a></li>
                            <li><a href="https://invincibleocean.com/software-product-development/"><span>✓</span> Product Development</a></li>
                            <li><a href="https://invincibleocean.com/phone-kyc/"><span>✓</span> Phone KYC API</a></li>
                            <li><a href="https://invincibleocean.com/credit-score-api/"><span>✓</span> Credit Score Check API</a></li>
                            <li><a href="https://invincibleocean.com/face-match-api-solution/"><span>✓</span> Face Match API</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-md-4 pr-5">
                        <h4 className={styles['footer-heading']}>Get In Touch</h4>
                        <ul className={styles['footer-list']}>
                            <li><a href='https://invincibleocean.com/terms-cancellation-policy/'><span>✓</span> Terms & Cancellation Policy</a></li>
                            <li><a href='https://invincibleocean.com/privacy-policy'><span>✓</span> Privacy Policy</a></li>
                            <li><a href="https://invincibleocean.com/dpa/"><span>✓</span> DPA Policy</a></li>
                        </ul>
                        <div className={styles['contact-info']}>
                            <h5>From Idea to Product</h5>
                            <p>201A SAS Tower Sector 38 Gurgaon-122003</p>
                            <p>contact@invincibleocean.com</p>
                            <p>+91 9599066061, 0124 4034247</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <h4 className={styles['footer-heading']}>See How We’ve Helped Everyone From Big Global Brands To Innovative Entrepreneurs To Build Software.</h4>
                        <p><span>Redefining Digital Onboarding Solutions</span></p>
                        <hr/>
                        <a href='https://invincibleocean.com/contact-us/'><button className='btn primary-button'>Contact us</button></a>
                    </div>
                </div>
            </div>
        </section>
    </>);
}
