import React, { useState } from 'react';
import style from './home.module.css'
import Footer from '../Components/Footer/Footer';
import Faqs from '../Components/Faqs/Faqs';
import api, { sendqueries } from '../Base/Config';
import Message from '../Components/Message/Message';


export default function Home(props) {
    const [ basicdetail, setBasicDetail ] = useState(true);
    const [ selectverification, setSelectVerification ] = useState(false);
    const [ sendrequest, setSendRequest ] = useState(false);
    const [ generatereport, setGenerateReport ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ contactMessage, setContactMessage ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');
    const [ loader, setLoader ] = useState(false);

    const faqs = [
        {
            id: 1,
            question: 'What verification services do you offer??',
            answer: 'We offer a comprehensive range of verification services, including email, phone number, Aadhaar card, PAN card, and face match.'
        },
        {
            id: 2,
            question: 'How accurate is your verification process?',
            answer: 'Our verification process is designed for accuracy, utilizing reliable sources and technology to ensure trustworthy results.'
        },
        {
            id: 3,
            question: 'What verification services do you offer?',
            answer: 'We provide a range of verification services, including email, phone number, Aadhaar card, PAN card, face match, employment HR verification, education details verification, bank account verification, Voter ID verification, court case check, driving license check, health BMI check, vehicle verification, address verification, and EPFO verification.'
        },
        {
            id: 4,
            question: 'Can you verify employment history?',
            answer: 'Yes, our employment HR verification service validates an individuals work history, aiding you in making informed choices.'
        },
        {
            id: 5,
            question: 'Is personal data kept secure during verification?',
            answer: 'Absolutely, we prioritize data security, implementing encryption and secure storage practices to safeguard personal information.'
        }
    ]

    const changeJourneyStep = (a, b, c, d) =>{
        setBasicDetail(a);
        setSelectVerification(b);
        setSendRequest(c);
        setGenerateReport(d);
    }

    const sendRequest = (e) => {
        e.preventDefault();
        setLoader(true);
        api.post(sendqueries, {
            name: name,
            email: email,
            phone: phone,
            productName: "Trubgc",
            message: contactMessage
        })
        .then((res) => {
            if(res.data.code === 100){
                setLoader(false);
                setMessageClass('success');
                setMessage(res.data.message);
                resetFunction();
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else {
                setMessageClass('error');
                setLoader(false);
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setMessageClass('error');
            setMessage("Something went wrong. try again later!");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const resetFunction = () => {
        setName('');
        setEmail('');
        setPhone('');
        setContactMessage('');
    }

    const closeMessage = () => { setMessage('') }
    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={closeMessage}/>}
        <section id={style['banner-form']}>
            <div className='container'>
                <div className={style['banner']}>
                    <div className={style['banner-header']}>
                        <div>
                            <img src='../images/logo.png' alt='invincible-ocean' />
                        </div>
                        <div>
                            <p><a href='https://invincibleocean.com/contact-us/'>Contact us</a></p>
                            <a href="https://dashboard.invincibleocean.com/bgc" rel="noreferrer" target="_blank"><button className="btn primary-button">Sign In</button></a>
                        </div>
                    </div>
                    <div className={style['banner-central-part']}>
                        <div className={style['left-content']}>
                            <h4>Trustworthy Background Verification Service -<span>TruBGC</span></h4>
                            <ul>
                                <li>Govt Document Verification</li>
                                <li>Hybrid Court Case Check Solution</li>
                                <li>Employment and Education Check</li>
                                <li>Video Based Liveliness Check</li>
                            </ul>
                            <div className={style['youtube-icon']}>
                            <a href="https://dashboard.invincibleocean.com/bgc" rel="noreferrer" target="_blank"><button className="btn primary-button mr-3">Get started</button></a>
                                {/* <span><img src='../images/landing-page/youtube-icon.png' alt=''/> See how it works</span>  */}
                            </div>
                        </div>
                        <div className={style['right-side-image']}>
                            <form onSubmit={sendRequest}>
                                <h3>Get in touch</h3>
                                <label>Name</label>
                                <input type="text" placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)} required/>
                                <label>E-mail</label>
                                <input type="text" placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                <label>Phone number</label>
                                <input type="text" placeholder='Enter number' value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                                <label>Message</label>
                                <textarea className="form-control mb-3" rows="3 " placeholder='Enter message' value={contactMessage} onChange={(e) => setContactMessage(e.target.value)} required></textarea>
                                {
                                    loader 
                                    ?<button className='btn primary-button w-100' disabled><i className="fa fa-circle-o-notch fa-spin"></i> Sending</button>
                                    :<button className='btn primary-button w-100'>Send message</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={style['seamless-flow']}>
            <div className='container'>
                <h2>Seamless Customizable flow</h2>
                <p>We combine a wide range of verification services to create a seamless and reliable process from start to end. Our user-friendly platform empowers you to customize the verification flow according to your specific requirements, ensuring thorough checks and peace of mind.</p>
                <img src='../images/seamless-customizable-flow.png' alt=''/>
            </div>
        </section>
        <section className={style['why-choose-us']}>
            <div className='container'>
                <div className={style['verification-image']}>
                    <p>Why choose us</p>
                    <h3>Elevate Your Verification Experience: Trust the Leaders</h3>
                </div>
                <div className={style['features']}>
                    <div>
                        <img src='../../images/landing-page/comprehensive-services.png' alt=''/>
                        <h2>Comprehensive Services</h2>
                        <p>We offer a wide range of verification services, combining all necessary checks to provide you with a complete picture of an individual's background.</p>
                    </div>
                    <div>
                        <img src='../images/landing-page/data-security.png' alt=''/>
                        <h2>Data Security</h2>
                        <p>We offer a wide range of verification services, combining all necessary checks to provide you with a complete picture of an individual's background.</p>
                    </div>
                    <div>
                        <img src='../images/landing-page/accurate-result.png' alt=''/>
                        <h2>Reliable and Accurate Results</h2>
                        <p>We offer a wide range of verification services, combining all necessary checks to provide you with a complete picture of an individual's background.</p>
                    </div>
                </div>
            </div>
        </section>
        <section id={style['truth-privacy-solutions']}>
            <div className='container'>
                <div>
                    <div>
                        <h4>Uncover the Truth, Build Trust</h4>
                        <p>IN TruBGC, we offer comprehensive background verification services to help you make informed decisions. Our meticulous checks leave no stone unturned, ensuring that you have the complete picture before making important commitments.</p>
                    </div>
                    <div>
                        <img src='./images/landing-page/build-trust.png' alt='build-trust' />
                    </div>
                </div>
                <div>
                    <div>
                        <img src='../images/landing-page/privacy.png' alt='privacy' />
                    </div>
                    <div>
                        <h4>Your Privacy, Our Priority</h4>
                        <p>we believe in upholding the highest ethical standards and complying with all legal requirements in our background verification processes. Trust is not just about the information we provide; it's about how we obtain it. With a strong commitment to integrity, we ensure that our practices are ethical, fair, and transparent.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <h4>Customized Solutions for Your Unique Needs</h4>
                        <p>We offer customized solutions to meet your unique needs. From selecting the verifications that matter most to you to designing a verification flow that aligns with your processes, we put you in control. Our goal is to provide you with a personalized experience that delivers the precise information you're seeking.</p>
                    </div>
                    <div>
                        <img src='../images/landing-page/customize-solution.png' alt='customize-solution' />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={style['step-to-confidence']}>
                    <div className={style['how-its-work']}>
                        <span>How it works</span>
                        <h3>"Step-by-Step to Confidence: Explore Our Verification Journey"</h3>
                    </div>
                    <div className={style['step-to-details']}>
                        <div className={style['step-to-left-side']}>
                            <img src='../images/landing-page/step-to-confidence.png' alt=''/>
                        </div>
                        <div className={style['verification-journey-steps']}>
                            <div className={style[basicdetail && 'active']} onClick={() => changeJourneyStep(true, false, false, false)}>
                                <div>
                                    <img src={`../images/landing-page/${basicdetail?'active-':''}one.png`} alt='one' />
                                </div>
                                <div>
                                    <h3>Add Basic Details</h3>
                                    <p>"Begin the trust journey by providing essential information, laying the foundation for accurate and reliable verifications."</p>
                                </div>
                            </div>
                            <div className={style[selectverification && 'active']} onClick={() => changeJourneyStep(false, true, false, false)}>
                                <div>
                                    <img src={`../images/landing-page/${selectverification?'active-':''}two.png`} alt='two' />
                                </div>
                                <div>
                                    <h3>Select Verifications</h3>
                                    <p>Customize Your Trust Journey,Tailor your verification experience by choosing the specific checks that matter to you, ensuring a personalized and comprehensive background assessment.</p>
                                </div>
                            </div>
                            <div className={style[sendrequest && 'active']} onClick={() => changeJourneyStep(false, false, true, false)}>
                                <div>
                                    <img src={`../images/landing-page/${sendrequest?'active-':''}three.png`} alt='three' />
                                </div>
                                <div>
                                    <h3>Send request</h3>
                                    <p>Initiating Your Verified Path, Once your verification request is sent, our dedicated team commences the meticulous process of verifying the provided details, ensuring thoroughness and accuracy.</p>
                                </div>
                            </div>
                            <div className={style[generatereport && 'active']} onClick={() => changeJourneyStep(false, false, false, true)}>
                                <div>
                                    <img src={`../images/landing-page/${generatereport?'active-':''}four.png`} alt='four' />
                                </div>
                                <div>
                                    <h3>Detailed Report Generated</h3>
                                    <p>Trust Anchored in Data, Experience the culmination of the verification journey with a detailed and reliable report, empowering you with verified insights and instilling confidence in your decisions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={style['key-benifits']}>
                    <span>Key Benefits</span>
                    <h3>Uncover the Power of Thorough and Accurate Checks</h3>
                </div>
                <div className={style['key-benifits-content-box']}>
                    <div className={style['uncover-the-benifits']}>
                        <img src='../images/landing-page/comprehensive-verification.png' alt='' />
                        <h4>Comprehensive Verifications</h4>
                        <p>Discover the Full Picture: Unleash the Power of Comprehensive Verification</p>
                    </div>
                    <div className={style['uncover-the-benifits']}>
                        <img src='../images/landing-page/customize-workflow.png' alt='' />
                        <h4>Customizable Workflow</h4>
                        <p>Trust Built on Integrity: Ethical Verification Solutions for Peace of Mind</p>
                    </div>
                    <div className={style['uncover-the-benifits']}>
                        <img src='../images/landing-page/user-friendly-interface.png' alt='' />
                        <h4>User-Friendly Interface</h4>
                        <p>Trust Built on Integrity: Ethical Verification Solutions for Peace of Mind</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={style['affordable-rates']}>
                    <div className={style['left-side-content']}>
                        <h3>Quality Verification at Affordable Rates: Your Budget-Friendly Solution</h3>
                        <p>₹99/- <span>Starting price</span></p>
                        <a href="https://dashboard.invincibleocean.com/bgc" rel="noreferrer" target="_blank"><button className='btn primary-button'>Get started</button></a>
                    </div>
                    <div className={style['right-side-content']}>
                        <img src='../images/landing-page/affordable-price.png' alt='' />
                    </div>
                </div>
            </div>
        </section>
        <Faqs heading="The Ultimate FAQ Guide: Understanding Background Verification" faqs={faqs} />
        <section>
            <div className='container'>
                <div className={style['start-to-end-verification']}>
                    <div className={style['sign-up-today']}>
                        <p>Experience the power of our start-to-end background verification system. Sign up today to streamline your verification process and make informed decisions with confidence.</p>
                        <a href="https://dashboard.invincibleocean.com/bgc" rel="noreferrer" target="_blank"><button className='btn primary-button'>Get started</button></a>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>);
}



